<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field businessDate">
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  v-model="dateDivision"
                  v-bind="dateDivisionDropdownListProps"
                />
              </li>
              <li class="item dateRange">
                <input-date-range type="lookup-condition" v-model="dateRange" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              매입처
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  cssClass="lookup-condition-multiselect"
                  v-model="filterOptions.cardCompanies"
                  v-bind="creditCardCompanyMultiSelectProps"
                  width="240"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              매출구분
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  cssClass="lookup-condition-multiselect"
                  v-model="filterOptions.salesDivisions"
                  v-bind="salesDivisionMultiSelectProps"
                  width="240"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              승인번호
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="filterOptions.approvalNumber"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.payerName }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="payerName"
                  @keydown.native="preventEnterEvent"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onFetchCreditCardReportsClick"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              buttonDiv="GET"
              :is-icon-custom="true"
              @click.native="onFetchDetailOptionsPopupOpenClick"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body" :class="bodyClassName">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article
        class="body-article"
        :style="{
          height: `calc(100% - ${
            visibleBottomLayer
            ? creditCardCompaniesChartRowCount * 25 + 99
            : 27
          }px)`,
        }"
      >
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">카드 매출 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      buttonDiv="PRINT"
                      @click.native="print"
                      :isShortcutButton="true"
                      :ignore="isPopupOpened"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="creditCardReportsGrid"
              v-bind="creditCardReportsGridProps"
              :dataSource="creditCardReportsGridDataSource"
              @headerCellInfo="onCreditCardReportsGridHeaderCellInfo"
              @queryCellInfo="onCreditCardReportsGridQueryCellInfo"
              @actionComplete="onCreditCardReportsGridActionComplete"
            />
          </div>
        </section>
      </article>
      <div
        class="layer"
        :style="{
          height: visibleBottomLayer
            ? `${creditCardCompaniesChartRowCount * 25 + 88}px`
            : '16px',
        }"
      >
        <div class="layerContent">
          <div class="content-wrapper">
            <div class="content-button">
              <ul class="button">
                <li class="arrow">
                  <erp-button button-div="CLOSE" @click.native="onLayerButtonClick"
                    >{{ layerButtonCaption }}
                  </erp-button>
                </li>
              </ul>
            </div>
            <div class="content-body">
              <article class="body-article article-02">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">카드사 합계</div>
                      <div class="header-caption">
                        {{ dateRange.from }} ~ {{ dateRange.to }} [{{
                          numberWithCommas(cardCompanies.length)
                        }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="print">
                          <erp-button
                              buttonDiv="GET"
                              @click.native="onPrintTotalChartClick"
                          >
                            카드매출 집계표 출력
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data header">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <template
                              v-for="i in creditCardCompaniesChartColCount"
                            >
                              <li
                                class="field"
                                v-bind:key="`credit-card-companies-chart-header-col-${i}-no`"
                              >
                                <div class="title">NO</div>
                              </li>
                              <li
                                class="field"
                                v-bind:key="`credit-card-companies-chart-header-col-${i}-card`"
                              >
                                <div class="title">카드사</div>
                              </li>
                              <li
                                class="field"
                                v-bind:key="`credit-card-companies-chart-header-col-${i}-approve`"
                              >
                                <div class="title">승인</div>
                              </li>
                              <li
                                class="field"
                                v-bind:key="`credit-card-companies-chart-header-col-${i}-cancel`"
                              >
                                <div class="title">취소</div>
                              </li>
                              <li
                                class="field"
                                v-bind:key="`credit-card-companies-chart-header-col-${i}-normal`"
                              >
                                <div class="title">정상</div>
                              </li>
                              <li
                                class="field"
                                v-bind:key="`credit-card-companies-chart-header-col-${i}-sales-amount`"
                              >
                                <div class="title" style="font-weight: bold;">매출금액</div>
                              </li>
                            </template>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-data body">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <template
                              v-for="(
                                i, iIdx
                              ) in creditCardCompaniesChartRowCount"
                            >
                              <template
                                v-for="(
                                  j, jIdx
                                ) in creditCardCompaniesChartColCount"
                              >
                                <template
                                  v-if="
                                    creditCardCompaniesChartMatrix[iIdx][jIdx]
                                  "
                                >
                                  <li
                                    class="field division"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-no`"
                                  >
                                    <div class="title">
                                      {{
                                        creditCardCompaniesChartMatrix[iIdx][
                                          jIdx
                                        ].no
                                      }}
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-card`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item center">
                                          {{
                                            creditCardCompaniesChartMatrix[
                                              iIdx
                                            ][jIdx].card
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-approve`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item right">
                                          {{
                                            creditCardCompaniesChartMatrix[iIdx][jIdx].approve > 0
                                              ? numberWithCommas(creditCardCompaniesChartMatrix[iIdx][jIdx].approve)
                                              : "-"
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-cancel`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item error right">
                                          {{
                                            creditCardCompaniesChartMatrix[iIdx][jIdx].cancel > 0
                                              ? numberWithCommas(creditCardCompaniesChartMatrix[iIdx][jIdx].cancel)
                                              : "-"
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-normal`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item right">
                                          {{
                                            creditCardCompaniesChartMatrix[iIdx][jIdx].normal > 0
                                              ? numberWithCommas(creditCardCompaniesChartMatrix[iIdx][jIdx].normal)
                                              : "-"
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-sales-amount`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item right" style="font-weight: bold;">
                                          {{
                                            creditCardCompaniesChartMatrix[iIdx][jIdx].salesAmount > 0
                                              ? numberWithCommas(creditCardCompaniesChartMatrix[iIdx][jIdx].salesAmount)
                                              : "-"
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </template>
                                <template
                                  v-else-if="
                                    creditCardCompaniesChartRowCount !==
                                      iIdx + 1 ||
                                    creditCardCompaniesChartColCount !==
                                      jIdx + 1
                                  "
                                >
                                  <li
                                    class="field division"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-no`"
                                  >
                                    <div class="title"></div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-card`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item center"></li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-approve`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item right"></li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-cancel`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item error right"></li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-normal`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item right"></li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-sales-amount`"
                                  >
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item right"></li>
                                      </ul>
                                    </div>
                                  </li>
                                </template>
                                <template v-else>
                                  <li
                                    class="field division"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-no`"
                                  >
                                    <div class="title">합계</div>
                                  </li>
                                  <li
                                    class="field division"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-card`"
                                  >
                                    <div class="title" />
                                  </li>
                                  <li
                                    class="field amount"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-approve`"
                                  >
                                    <div class="title">
                                      {{
                                        creditCardTotalIncludesAmounts.approve > 0
                                          ? numberWithCommas(creditCardTotalIncludesAmounts.approve)
                                          : "-"
                                      }}
                                    </div>
                                  </li>
                                  <li
                                    class="field amount"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-cancel`"
                                  >
                                    <div class="title">
                                      {{
                                        creditCardTotalIncludesAmounts.cancel > 0
                                          ? numberWithCommas(creditCardTotalIncludesAmounts.cancel)
                                          : "-"
                                      }}
                                    </div>
                                  </li>
                                  <li
                                    class="field amount"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-normal`"
                                  >
                                    <div class="title">
                                      {{
                                        creditCardTotalIncludesAmounts.normal > 0
                                          ? numberWithCommas(creditCardTotalIncludesAmounts.normal)
                                          : "-"
                                      }}
                                    </div>
                                  </li>
                                  <li
                                    class="field amount"
                                    v-bind:key="`credit-card-companies-chart-col-${i}-${j}-sales-amount`"
                                  >
                                    <div class="title" style="font-weight: bold;">
                                      {{
                                        creditCardTotalIncludesAmounts.salesAmount > 0
                                          ? numberWithCommas(creditCardTotalIncludesAmounts.salesAmount)
                                          : "-"
                                      }}
                                    </div>
                                  </li>
                                </template>
                              </template>
                            </template>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <credit-card-reports-filter-options-popup
      ref="creditCardReportsFilterOptionsPopup"
      v-if="visibleCreditCardReportsFilterOptionsPopup"
      :filter-options="filterOptions"
      :labels="labels"
      @submit="onCreditCardReportsFilterOptionsPopupSubmit"
      @close="onCreditCardReportsFilterOptionsPopupClose"
    />
    <print-credit-card-total-records-popup
      ref="printCreditCardTotalRecordsPopup"
      v-if="visiblePrintCreditCardTotalRecordsPopup"
      :date-range="dateRange"
      :filter-options="filterOptions"
      :credit-card-total-records="creditCardCompaniesIncludesAmounts"
      :popupSearchOptions="popupSearchOptions"
      @close="onPrintCreditCardTotalRecordsPopupClose"
    />
    <pay-cancel-popup
      v-if="visiblePayCancelPopup"
      ref="payCancelPopup"
      @popupClosed="payCancelPopupClose"
    />
    <re-issue-receipt-popup
      v-if="visibleReIssueReceiptPopup"
      ref="reIssueReceiptPopup"
      @popupClosed="reIssueReceiptPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.businessDate .content .item.select{width:120px}
body .layer{position:absolute;left:0;bottom:0;width:100%;height:18px;background-color:#fff}
body .layerContent{box-sizing:border-box;height:100%;border-top:1px solid #ccc;box-shadow:0 -3px 3px 0 rgba(0,0,0,0.03)}
body .layerContent .content-body{background-color:#fff!important}
body .layerContent .article-section .body-data .field{width:calc((100% / 18) + 1px)}
body .layerContent .article-section .body-data.header .data-content .title,body .layerContent .article-section .body-data.body .field.division .title{width:100%;justify-content:center}
body .layerContent .article-section .body-data.body .field.amount .title{padding:3px 6px 2px;width:100%;justify-content:flex-end}
body .layerContent .article-section .body-data.body .field .content .item.center{text-align:center}
body .layerContent .article-section .body-data.body .field .content .item.right{text-align:right}
body .layerContent .article-section .body-data.body .field .content .item.error{color:red}
body .content-button .button{list-style:none;padding:0;margin:0}
body .content-button .button li{position:absolute;left:0;top:-22px}
body .content-button .button li >>> .e-btn{display:block;overflow:hidden;box-shadow:none;width:53px;height:23px;padding:0;border:none;background:transparent url(../../assets/images/common/layer-button-scale-up.png) no-repeat center 0;text-indent:-1000px}
body .content-body.dev-front-checkin-thumbnail .content-button .button li >>> .e-btn{background-position:center -23px}
body .content-button .button li.arrow >>> .e-btn{background-position:center 0}
body .content-button .button li.refresh >>> .e-btn{background-position:center -34px}
body .content-button .button li.close >>> .e-btn{background-position:center -51px}
body .content-button .button li.arrow{left:calc(50% - 16px)}
body .content-button .button li.refresh{left:auto;right:47px}
body .content-button .button li.close{left:auto;right:14px}
body .content-button .button li >>> .e-btn .e-ripple-element{background-color:transparent}
body .content-body .body-article.article-02 .article-section{height:auto}
body .content-body .article-section .section-body{overflow:hidden;border:none}
body .appContent .article-section.section-0104 .body-data .field{width:calc((100% / 6) + 1px)}
body .content-body .article-section .section-body >>> .e-grid.e-lib .dropdownbutton{margin:0 -8px}
body .content-body .article-section .section-body >>> .e-grid.e-lib .dropdownbutton .e-btn{overflow:hidden;box-shadow:none;width:23px;height:23px;padding:0;border:none;border-radius:0;background:transparent url(../../assets/images/common/button-icon-etc.png) no-repeat center center;text-indent:-1000px}
</style>

<script>
import {sumBy as _sumBy, groupBy as _groupBy, orderBy as _orderBy, sortBy as _sortBy} from "lodash";
import moment from "moment";
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputText from '@/components/common/text/InputText';
import InputDateRange from "@/components/common/datetime/InputDateRange";
import GolfERPService from "@/service/GolfERPService";
import ReportView from "@/components/common/report/ReportView";

import {
  DATE_FORMAT_YYYY_MM_DD,
  getDayOfWeekCaption,
  getDayColorValue,
} from '@/utils/date';
import CreditCardReportsFilterOptionsPopup from "@/views/credit-management/popup/CreditCardReportsFilterOptionsPopup";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  commonCodesGetColorValue, commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import {
  Aggregate,
  Edit,
  ExcelExport,
  ForeignKey,
  Group,
  Resize,
  Filter,
  Page,
} from "@syncfusion/ej2-vue-grids";
import { numberWithCommas } from "@/utils/number";
import PrintCreditCardTotalRecordsPopup from "@/views/credit-management/popup/PrintCreditCardTotalRecordsPopup";

import EjsGridDropdownButtonColumn from "../../components/common/EjsGridDropdownButtonColumn";
import PayCancelPopup from "@/components/popups/PayCancelPopup";
import ReIssueReceiptPopup from "@/components/popups/ReIssueReceiptPopup";
import { mapGetters } from "vuex";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {formPreventEnterEvent} from '@/utils/formUtil';

export default {
  name: "CreditCardReports",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    ReIssueReceiptPopup,
    PayCancelPopup,
    PrintCreditCardTotalRecordsPopup,
    EjsGridWrapper,
    CreditCardReportsFilterOptionsPopup,
    InputText,
    InputDateRange,
    ReportView,
    ErpButton,
  },
  filters: {
    numberWithCommas,
  },
  async created() {
    await this.initialize();
  },
  data() {
    return {
      popupSearchOptions: null,
      count: 0,

      isLoading: false,
      cardCompanies: [],
      creditCardReports: [],

      now: null,
      dateDivision: "business",
      dateRange: {
        from: null,
        to: null,
      },
      payerName: "",
      filterOptions: {
        cardCompanies: [],
        salesDivisions: [],
        approvalDivisions: ["OK", "T_OK"],
        trnscDiv: "",
        storeCode: "",
        businessCode: "",
        approvalNumber: "",
        cardNumber: "",
        cancelViewFlag: false,
      },

      labels: {
        payerName: "지불자명",
        cardCompanies: "매입처",
        salesDivisions: "매출구분",
        approvalDivisions: "승인구분",
        trnscDiv: "거래구분",
        storeCode: "매장",
        businessCode: "영업장",
        approvalNumber: "승인번호",
        cardNumber: "카드번호",
        cancelViewFlag: "원거래 취소자료 보기",
      },

      visibleBottomLayer: true,
      visibleCreditCardReportsFilterOptionsPopup: false,
      visiblePrintCreditCardTotalRecordsPopup: false,
      visiblePrintPaper: false,

      visiblePayCancelPopup: false,
      visibleReIssueReceiptPopup: false,
    };
  },
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return (
        this.visibleCreditCardReportsFilterOptionsPopup ||
        this.visiblePrintCreditCardTotalRecordsPopup ||
        this.visiblePayCancelPopup ||
        this.visibleReIssueReceiptPopup
      );
    },
    creditCardCompanyMultiSelectProps() {
      const dataSource = _sortBy(
        this.cardCompanies,
        "order"
      ).map(({ id, name: text }) => ({ value: `${id}`, text }));
      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
        placeholder: "전체",
      };
    },
    salesDivisionMultiSelectProps() {
      const dataSource = _sortBy(
        commonCodesGetCommonCode("SALES_DIV"),
        "sortNo"
      ).map(({ comCode: value, comName: text }) => ({ value, text }));
      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
        placeholder: "전체",
      };
    },
    nonNullDatetime() {
      const defaultValue = new Date();
      if (!this.now) {
        return defaultValue;
      }

      if (this.now instanceof Date) {
        return this.now;
      }

      if (typeof this.now === "string") {
        const datetimeMoment = moment(this.now);
        if (datetimeMoment.isValid()) {
          return datetimeMoment.toDate();
        }
      }

      return defaultValue;
    },
    datetimeString() {
      return moment(this.nonNullDatetime).format(`YYYY-MM-DD HH:mm:ss`);
    },

    bodyClassName() {
      return this.visibleBottomLayer
        ? this.$t("className.frontCheckin.thumbnail")
        : null;
    },
    dateDivisionDropdownListProps() {
      return {
        allowFiltering: false,
        dataSource: [
          {
            value: "business",
            text: "영업일자",
          },
          {
            value: "approval",
            text: "승인일자",
          },
        ],
        fields: {
          text: "text",
          value: "value",
        },
      };
    },
    creditCardTotalIncludesAmounts() {
      return {
        approve: _sumBy(this.creditCardCompaniesIncludesAmounts, "approve"),
        cancel: _sumBy(this.creditCardCompaniesIncludesAmounts, "cancel"),
        normal: _sumBy(this.creditCardCompaniesIncludesAmounts, "normal"),
        salesAmount: _sumBy(
          this.creditCardCompaniesIncludesAmounts,
          "salesAmount"
        ),
      };
    },
    creditCardCompaniesIncludesAmounts() {
      const creditCardReportsGrouped = _groupBy(
        this.creditCardReports,
        "purchaseId"
      );

      return this.cardCompanies.map(({ id, name, order, feeRate, vatCalculationMethod, calculationDigits }) => {
        const target = creditCardReportsGrouped[id];
        const approve = !target
          ? 0
          : target.filter(({ approvalDivision }) => approvalDivision.toUpperCase() === "OK" || approvalDivision.toUpperCase() === "T_OK").length;
        const cancel = !target
          ? 0
          : target.filter(({ approvalDivision }) => approvalDivision.toUpperCase() === "CANCEL" || approvalDivision.toUpperCase() === "T_CANCEL").length;
        const normal = !target
          ? 0
          : target.filter(({ approvalDivision, deleted }) => (approvalDivision.toUpperCase() === "OK" || approvalDivision.toUpperCase() === "T_OK") && !deleted).length;
        const salesAmount = !target
          ? 0
          : _sumBy(
              target,
              ({ approvalDivision, approvalAmount }) =>
                Math.abs(approvalAmount) *
                (approvalDivision.toUpperCase() === "CANCEL" ||
                approvalDivision.toUpperCase() === "T_CANCEL"
                  ? -1
                  : 1)
            );
        const approveAmount = !target
          ? 0
          : _sumBy(
            target,
            ({ approvalDivision, approvalAmount }) =>
              (approvalDivision.toUpperCase() === "OK" ||
              approvalDivision.toUpperCase() === "T_OK") ?
                Math.abs(approvalAmount) :
                0
          );
        const cancelAmount = !target
          ? 0
          : _sumBy(
            target,
            ({ approvalDivision, approvalAmount }) =>
              approvalDivision.toUpperCase() === "CANCEL" ||
              approvalDivision.toUpperCase() === "T_CANCEL" ?
                Math.abs(approvalAmount) :
                0
          )
        ;
        const commission = salesAmount > 0
          ? vatCalculationMethod === "TRUNC"
            ? Math.trunc(salesAmount * feeRate * (calculationDigits > 0 ? Math.pow(10, calculationDigits) : 1)) / (calculationDigits > 0 ? Math.pow(10, calculationDigits) : 1)
            : Math.round(salesAmount * feeRate * (calculationDigits > 0 ? Math.pow(10, calculationDigits) : 1)) / (calculationDigits > 0 ? Math.pow(10, calculationDigits) : 1)
          : 0
        ;
        const actualSalesAmount = salesAmount - commission;

        return {
          id,
          card: name,
          order,
          approve,
          cancel,
          normal,
          salesAmount,
          approveAmount,
          cancelAmount,
          commission,
          actualSalesAmount,
        };
      });
    },
    creditCardCompaniesChartMatrix() {
      const matrix = [
        ...Array(this.creditCardCompaniesChartRowCount).keys(),
      ].map(() => []);

      _orderBy(this.creditCardCompaniesIncludesAmounts, "order").forEach(
        ({ id, card, approve, cancel, normal, salesAmount }, index) => {
          matrix[Math.floor(index / 3)].push({
            id,
            no: index + 1,
            card,
            approve,
            cancel,
            normal,
            salesAmount,
          });
        }
      );

      return matrix;
    },
    creditCardCompaniesChartRowCount() {
      return Math.floor(this.cardCompanies.length / 3) + 1;
    },
    creditCardCompaniesChartColCount() {
      return 3;
    },
    creditCardReportsGridColumns() {
      return [
        {
          field: "_rid",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "id",
          visible: false,
        },
        {
          field: "deleted",
          visible: false,
        },
        {
          allowEditing: false,
          field: "businessName",
          headerText: "영업장",
          minWidth: 16,
          textAlign: "Left",
          width: 90,
          type: "string",
        },
        {
          allowEditing: false,
          groupCode: "SALES_DIV",
          editType: "dropdownedit",
          field: "salesDivision",
          isCommonCodeField: true,
          headerText: "매출구분",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          field: "salesDate",
          headerText: "영업일자",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          groupCode: "BSN_CODE",
          editType: "dropdownedit",
          field: "businessDivisionCode",
          isCommonCodeField: true,
          headerText: "영업구분",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          field: "salesDateOfWeekCaption",
          headerText: "요일",
          minWidth: 16,
          textAlign: "Center",
          width: 70,
          type: "string",
        },
        {
          allowEditing: false,
          field: "purchaseName",
          headerText: "카드사",
          minWidth: 16,
          textAlign: "Center",
          width: 80,
          type: "string",
        },
        {
          allowEditing: false,
          field: "number",
          headerText: "카드번호",
          minWidth: 16,
          textAlign: "Left",
          width: 140,
          type: "string",
        },
        {
          allowEditing: false,
          groupCode: "APPROVAL_DIV",
          editType: "dropdownedit",
          field: "approvalDivision",
          isCommonCodeField: true,
          headerText: "승인구분",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          field: "approvalNumber",
          headerText: "승인번호",
          minWidth: 16,
          textAlign: "Left",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          field: "contextMenu",
          headerText: "",
          minWidth: 16,
          textAlign: "Left",
          width: 24,
          template: () => {
            const onReportRecordDropdownButtonSelect = this
              .onReportRecordDropdownButtonSelect;
            return {
              template: {
                components: { EjsGridDropdownButtonColumn },
                template:
                  "<ejs-grid-dropdown-button-column :dropdownButtonItems='dropdownButtonItems' @select='onDropdownButtonSelect'/>",
                data() {
                  return {
                    data: {},
                  };
                },
                computed: {
                  dropdownButtonItems() {
                    if (
                      (this.data.approvalDivision === "OK" ||
                        this.data.approvalDivision === "T_OK") &&
                      !this.data.deleted
                    ) {
                      return [
                        {
                          text: "정산취소",
                          value: "cancel",
                        },
                        {
                          text: "영수증 재발행",
                          value: "republic",
                        },
                      ];
                    } else {
                      return [
                        {
                          text: "영수증 재발행",
                          value: "republic",
                        },
                      ];
                    }
                  },
                },
                methods: {
                  onDropdownButtonSelect(args) {
                    onReportRecordDropdownButtonSelect({
                      args,
                      data: this.data,
                    });
                  },
                },
              },
            };
          },
        },
        {
          allowEditing: false,
          field: "approvalAmount",
          headerText: "승인금액",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          isNumericType: true,
          width: 80,
        },
        {
          allowEditing: false,
          field: "taxAmount",
          headerText: "과세",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          isNumericType: true,
          width: 80,
        },
        {
          allowEditing: false,
          field: "vatAmount",
          headerText: "부가세",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          isNumericType: true,
          width: 80,
        },
        {
          allowEditing: false,
          field: "noTaxAmount",
          headerText: "면세",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          isNumericType: true,
          width: 80,
        },
        {
          allowEditing: false,
          field: "installmentPeriod",
          headerText: "할부기간",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "number",
          isNumericType: true,
        },
        {
          allowEditing: false,
          groupCode: "STORE_CODE",
          editType: "dropdownedit",
          field: "storeCode",
          isCommonCodeField: true,
          headerText: "매장",
          minWidth: 16,
          textAlign: "Center",
          width: 70,
          type: "string",
        },
        {
          allowEditing: false,
          headerText: "지불자",
          field: "payerName",
          type: "string",
          width: "90",
          textAlign: "left",
        },
        {
          allowEditing: false,
          headerText: "지불자 정보",
          field: "payerInfo",
          type: "string",
          width: "120",
          textAlign: "left",
        },
        {
          allowEditing: false,
          field: "terminalId",
          headerText: "단말ID",
          minWidth: 16,
          textAlign: "Center",
          width: 120,
          type: "string",
        },
        {
          allowEditing: false,
          groupCode: "TRNSC_DIV",
          editType: "dropdownedit",
          field: "trnscDiv",
          isCommonCodeField: true,
          headerText: "거래구분",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          field: "insertUserName",
          headerText: "승인처리자",
          minWidth: 16,
          textAlign: "Center",
          width: 120,
          type: "string",
        },
        {
          allowEditing: false,
          field: "approvalDateTime",
          headerText: "승인일시",
          minWidth: 16,
          textAlign: "Left",
          width: 120,
          type: "string",
        },
      ];
    },
    creditCardReportsGridDataSource() {
      return _orderBy(
        this.creditCardReports.map(
          (
            {
              id,
              deleted,
              salesDivision,
              businessName,
              salesDate,
              businessDivisionCode,
              purchaseName,
              number,
              approvalDivision,
              approvalNumber,
              approvalAmount,
              telCancelFlag,
              taxAmount,
              vatAmount,
              noTaxAmount,
              installmentPeriod,
              storeCode,
              payerName,
              payerInfo,
              terminalId,
              trnscDiv,
              insertUser,
              approvalDateTime,
              visitId,
              stayId,
            },
            index
          ) => ({
            _rid: index + 1,
            id,
            deleted,
            salesDivision,
            businessName,
            salesDate,
            salesDateOfWeekCaption: getDayOfWeekCaption(salesDate),
            businessDivisionCode,
            purchaseName,
            number,
            approvalDivision,
            approvalNumber,
            approvalAmount,
            telCancelFlag,
            taxAmount,
            vatAmount,
            noTaxAmount,
            installmentPeriod,
            storeCode,
            payerName,
            payerInfo,
            terminalId,
            trnscDiv,
            insertUserName: insertUser ? insertUser.name : "",
            approvalDateTime,
            visitId,
            stayId,
          })
        ),
        ["salesDate", "approvalDateTime"],
        ["asc", "desc"]
      );
    },
    creditCardReportsGridProps() {
      return {
        allowFiltering: true,
        allowGrouping: false,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        allowPaging: true,
        allowExcelExport: true,
        columns: this.creditCardReportsGridColumns,
        // groupSettings: {
        //   columns: ["salesDate", "salesDivision"],
        //   showDropArea: false
        // },
        pageSettings: { pageSize: 50 },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          ExcelExport,
          Filter,
          Page,
        ],
        selectionSettings: { type: "Single", enableToggle: false },
      };
    },
    layerButtonCaption() {
      return this.visibleBottomLayer ? "닫기" : "열기";
    },
  },
  asyncComputed: {},
  methods: {
    numberWithCommas,
    preventEnterEvent: formPreventEnterEvent,
    async onFetchCreditCardReportsClick() {
      await this.fetchCreditCardReports();
    },
    onFetchDetailOptionsPopupOpenClick() {
      this.visibleCreditCardReportsFilterOptionsPopup = true;
    },
    onCreditCardReportsGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node
      } = args;
      if (field === "contextMenu") {
        node.classList.add(this.$t("className.grid.contextMenuArea"));
      }
    },
    onCreditCardReportsGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (field === "salesDateOfWeekCaption") {
        cell.style.color = getDayColorValue(data.dwCode, data.businessDivisionCode);
      }
      if (field === "businessDivisionCode") {
        cell.style.color = commonCodesGetColorValue(
          "BSN_CODE",
          data.businessDivisionCode
        );
      }
      if (field === "approvalDivision") {
        if (
          (data.approvalDivision === "OK" || data.approvalDivision === "T_OK") &&
          data.deleted
        ) {
          cell.style.color = "red";
        } else {
          cell.style.color = commonCodesGetColorValue(
            "APPROVAL_DIV",
            data.approvalDivision
          );
        }
      }
      if (
        field === "approvalNumber" &&
        data.telCancelFlag
      ) {
        cell.style.color = 'red';
      }
      if (
        (field === "approvalAmount" ||
          field === "noTaxAmount") &&
        0 < Math.abs(data.noTaxAmount)
      ) {
        cell.style.color = commonCodesGetColorValue("NOTAX_CODE", "NOTAX");
      }
      if (field === "number") {
        const number = args.data["number"];
        cell.innerText =
          number.substring(0, 4) +
          "-" +
          number.substring(4, 8) +
          "-" +
          number.substring(8, 12) +
          "-" +
          number.substring(12, 16);
      }
    },
    onCreditCardReportsGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.creditCardReportsGrid?.getGridBatchCount() || 0
      );
    },
    async payCancelPopupClose(args) {
      this.visiblePayCancelPopup = false;

      if (args !== undefined && args.isReload) {
        await this.onFetchCreditCardReportsClick();
      }
    },
    reIssueReceiptPopupClose() {
      this.visibleReIssueReceiptPopup = false;
    },
    onLayerButtonClick() {
      this.visibleBottomLayer = !this.visibleBottomLayer;
    },
    async onPrintTotalChartClick() {
      const cardCompanies = await GolfERPService.fetchAccountCardCompanies();
      const workPlaces = await GolfERPService.fetchWorkPlaces();

      let cardCompaniesCaptionList = [];
      let salesDivisionsCaptionList = [];
      let approvalDivisionsCaptionList = [];

      if (this.filterOptions.cardCompanies.length > 0) {
        this.filterOptions.cardCompanies.map((item) =>
          cardCompaniesCaptionList.push(
            cardCompanies.find((i) => i.id.toString() === item).name
          )
        );
      }
      if (this.filterOptions.salesDivisions.length > 0) {
        this.filterOptions.salesDivisions.map((item) =>
          salesDivisionsCaptionList.push(
            commonCodesGetComName("SALES_DIV", item)
          )
        );
      }
      if (this.filterOptions.approvalDivisions.length > 0) {
        this.filterOptions.approvalDivisions.map((item) =>
          approvalDivisionsCaptionList.push(
            commonCodesGetComName("APPROVAL_DIV", item)
          )
        );
      }
      const searchOptionsList = [
        {
          key: this.labels.payerName,
          value: this.payerName !== ''
            ? this.payerName
            : null,
        },
        {
          key: this.dateDivisionDropdownListProps.dataSource.find(
            (item) => item.value === this.dateDivision
          ).text,
          value: this.dateRange.from + " ~ " + this.dateRange.to,
        },
        {
          key: this.labels.cardCompanies,
          value: cardCompaniesCaptionList.join(", "),
        },
        {
          key: this.labels.salesDivisions,
          value: salesDivisionsCaptionList.join(", "),
        },
        {
          key: this.labels.approvalDivisions,
          value: approvalDivisionsCaptionList.join(", "),
        },
        {
          key: this.labels.trnscDiv,
          value: commonCodesGetComName(
            "TRNSC_DIV",
            this.filterOptions.trnscDiv
          ),
        },
        {
          key: this.labels.storeCode,
          value: commonCodesGetComName(
            "STORE_CODE",
            this.filterOptions.storeCode
          ),
        },
        {
          key: this.labels.businessCode,
          value:
            this.filterOptions.businessCode !== ""
              ? workPlaces.find(
              (item) =>
                item.id.toString() === this.filterOptions.businessCode
              ).name
              : null,
        },
        {
          key: this.labels.approvalNumber,
          value: this.filterOptions.approvalNumber,
        },
        {
          key: this.labels.cardNumber,
          value: this.filterOptions.cardNumber,
        },
        {
          key: this.labels.cancelViewFlag,
          value: this.filterOptions.cancelViewFlag ? "예" : null,
        },
      ];

      this.popupSearchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      this.visiblePrintCreditCardTotalRecordsPopup = true;
    },
    async onCreditCardReportsFilterOptionsPopupSubmit(payload) {
      this.filterOptions = payload;
      await this.fetchCreditCardReports();
    },
    onCreditCardReportsFilterOptionsPopupClose() {
      this.visibleCreditCardReportsFilterOptionsPopup = false;
    },
    onPrintCreditCardTotalRecordsPopupClose() {
      this.visiblePrintCreditCardTotalRecordsPopup = false;
    },
    onPrintPaperClose() {
      this.visiblePrintPaper = false;
    },
    onReportRecordDropdownButtonSelect(event) {
      const {
        args: {
          item: { value: menu },
        },
        data: data,
      } = event;

      if (
        (data.approvalDivision.toUpperCase() === "OK" ||
          data.approvalDivision.toUpperCase() === "T_OK") &&
        data.deleted
      ) {
        this.errorToast("이미 승인 취소된 자료 입니다");
        return;
      }

      if (menu === "cancel") {
        this.visiblePayCancelPopup = true;
        this.$nextTick(() => {
          this.$refs.payCancelPopup.showPayCancelPopup({
            salesDiv: data.salesDivision,
            payDate: data.salesDate,
            cardId: data.id,
            payDiv: "CARD",
            bsnDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
            payAmt: data.approvalAmount,
            visitName: "",
            teamGrpFlag: null,
            grpNo: null,
            storeCode: data.storeCode,
            isOnlyCardCancel: true,
            visitId: data.visitId,
            stayId: data.stayId,
          });
        });
      } else if (menu === "republic") {
        this.visibleReIssueReceiptPopup = true;
        this.$nextTick(() => {
          this.$refs.reIssueReceiptPopup.showReIssueReceiptPopup({
            payId: data.id,
            salesDiv: data.salesDivision,
            payDiv: "CARD",
          });
        });
      }
    },
    async initialize() {
      this.isLoading = true;

      try {
        const [nowMoment, cardCompanies] = await Promise.all([
          GolfERPService.fetchNow(),
          GolfERPService.fetchAccountCardCompanies(),
        ]);

        this.now = nowMoment.toDate();

        const from = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
        const to = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
        this.dateRange = {
          from,
          to,
        };
        this.cardCompanies = cardCompanies;

        await this.fetchCreditCardReports();
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCreditCardReports() {
      if (!this.dateRange.from) {
        const columnName = this.dateDivisionDropdownListProps.dataSource.find(
          (item) => item.value === this.dateDivision
        );

        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", [columnName.text])
        );
      }
      if (!this.dateRange.to) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      this.isLoading = true;

      let filterOptionArgs = JSON.parse(JSON.stringify(this.filterOptions));

      if (filterOptionArgs.approvalDivisions.length === 4) {
        filterOptionArgs.approvalDivisions = [];
      }

      try {
        this.creditCardReports = await GolfERPService.fetchCreditCardReports(
          this.payerName,
          this.dateDivision,
          this.dateRange.from,
          this.dateRange.to,
          filterOptionArgs
        );
      } finally {
        this.isLoading = false;
      }
    },
    async print() {
      const options = [
        {
          field: "salesDivision",
          comCode: "SALES_DIV",
          format: "comCode",
        },
        {
          field: "businessDivisionCode",
          comCode: "BSN_CODE",
          format: "comCode",
        },
        {
          field: "approvalDivision",
          comCode: "APPROVAL_DIV",
          format: "comCode",
        },
        {
          field: "trnscDiv",
          comCode: "TRNSC_DIV",
          format: "comCode",
        },
        {
          field: "storeCode",
          comCode: "STORE_CODE",
          format: "comCode",
        },
      ];

      let result = this.$refs.creditCardReportsGrid.getGridBatchData(options);
      result = result.map((item) => {
        item.tempCount = 1;
        return item;
      });

      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const cardCompanies = await GolfERPService.fetchAccountCardCompanies();
      const workPlaces = await GolfERPService.fetchWorkPlaces();

      let cardCompaniesCaptionList = [];
      let salesDivisionsCaptionList = [];
      let approvalDivisionsCaptionList = [];

      if (this.filterOptions.cardCompanies.length > 0) {
        this.filterOptions.cardCompanies.map((item) =>
          cardCompaniesCaptionList.push(
            cardCompanies.find((i) => i.id.toString() === item).name
          )
        );
      }
      if (this.filterOptions.salesDivisions.length > 0) {
        this.filterOptions.salesDivisions.map((item) =>
          salesDivisionsCaptionList.push(
            commonCodesGetComName("SALES_DIV", item)
          )
        );
      }
      if (this.filterOptions.approvalDivisions.length > 0) {
        this.filterOptions.approvalDivisions.map((item) =>
          approvalDivisionsCaptionList.push(
            commonCodesGetComName("APPROVAL_DIV", item)
          )
        );
      }

      const searchOptionsList = [
        {
          key: this.labels.payerName,
          value: this.payerName !== ''
              ? this.payerName
              : null,
        },
        {
          key: this.dateDivisionDropdownListProps.dataSource.find(
            (item) => item.value === this.dateDivision
          ).text,
          value: this.dateRange.from + " ~ " + this.dateRange.to,
        },
        {
          key: this.labels.cardCompanies,
          value: cardCompaniesCaptionList.join(", "),
        },
        {
          key: this.labels.salesDivisions,
          value: salesDivisionsCaptionList.join(", "),
        },
        {
          key: this.labels.approvalDivisions,
          value: approvalDivisionsCaptionList.join(", "),
        },
        {
          key: this.labels.trnscDiv,
          value: commonCodesGetComName(
            "TRNSC_DIV",
            this.filterOptions.trnscDiv
          ),
        },
        {
          key: this.labels.storeCode,
          value: commonCodesGetComName(
            "STORE_CODE",
            this.filterOptions.storeCode
          ),
        },
        {
          key: this.labels.businessCode,
          value:
            this.filterOptions.businessCode !== ""
              ? workPlaces.find(
                  (item) =>
                    item.id.toString() === this.filterOptions.businessCode
                ).name
              : null,
        },
        {
          key: this.labels.approvalNumber,
          value: this.filterOptions.approvalNumber,
        },
        {
          key: this.labels.cardNumber,
          value: this.filterOptions.cardNumber,
        },
        {
          key: this.labels.cancelViewFlag,
          value: this.filterOptions.cancelViewFlag ? "예" : null,
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result
            ?.map(item => {
              if (item.telCancelFlag) {
                // TODO : 추후 레포트 승인구분, 승인번호 색상 처리
                item.telCancelFlag = false;
              }
              return item;
            }),
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(
        reportData,
        this.$options.name
      );
    },
    onClickExcel() {
      this.$refs.creditCardReportsGrid.excelExport();
    },
  },
};
</script>
