<template>
  <div id="popupWrapper">
    <ejs-dialog
      allowDragging="true"
      header="카드 매출 집계표 출력"
      isModal="true"
      ref="dialog"
      showCloseIcon="true"
      width="1150"
      v-bind="dialogProps"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window printCreditCardTotalRecords">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <ul class="lookup-condition">
                <li class="field">
                  <div class="title">매출구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        cssClass="lookup-condition-multiselect"
                        v-model="filterOptions.salesDivisions"
                        v-bind="salesDivisionMultiSelectProps"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">영업장</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        cssClass="lookup-condition-dropdown"
                        v-model="filterOptions.businessCode"
                        v-bind="businessDropdownListProps"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">영업일자</div>
                  <ul class="content">
                    <li class="item dateRange">
                      <input-date-range
                        type="lookup-condition"
                        v-model="dateRange"
                        :disabled="true"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="content-body">
              <ReportView ref="reportViewComponent" :isPopup="true" />
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="creditCardTotalRecordGrid"
                        v-bind="creditCardTotalRecordGridProps"
                        @headerCellInfo="onCreditCardTotalRecordGridHeaderCellInfo"
                        @queryCellInfo="onCreditCardTotalRecordGridQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="print keyColor">
              <erp-button
                  buttonDiv="PRINT"
                  @click.native="print"
                  :isShortcutButton="true"
              >
                인쇄
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import { sortBy as _sortBy, orderBy as _orderBy } from "lodash";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { Aggregate, Resize, Filter } from "@syncfusion/ej2-vue-grids";
import ReportView from "@/components/common/report/ReportView";
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "PrintCreditCardTotalRecordsPopup",
  components: {
    EjsGridWrapper,
    InputDateRange,
    ReportView,
    ErpButton
  },
  props: {
    popupSearchOptions: {
      type: String,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: Object,
      required: true,
    },
    creditCardTotalRecords: {
      type: Array,
      required: true,
    },
  },
  async mounted() {
    try {
      await this.init();
      this.show();
    } catch (e) {
      this.hide();
    }
  },
  data() {
    return {
      isLoading: false,

      now: null,
      workPlaces: [],

      visiblePrintPaper: false,
    };
  },
  computed: {
    ...mapGetters(["username"]),
    datetimeString() {
      return moment(this.now).format(`YYYY-MM-DD HH:mm:ss`);
    },
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
    salesDivisionMultiSelectProps() {
      const dataSource = _sortBy(
        commonCodesGetCommonCode("SALES_DIV"),
        "sortNo"
      ).map(({ comCode: value, comName: text }) => ({ value, text }));
      return {
        allowFiltering: false,
        dataSource,
        disabled: true,
        fields: {
          text: "text",
          value: "value",
        },
        placeholder: "전체",
      };
    },
    businessDropdownListProps() {
      const dataSource = _sortBy(
        this.workPlaces,
        "order"
      ).map(({ id, name: text }) => ({ value: `${id}`, text }));
      dataSource.unshift({ value: "", text: "ALL" });
      return {
        allowFiltering: false,
        dataSource,
        enabled: false,
        fields: {
          text: "text",
          value: "value",
        },
      };
    },
    creditCardTotalRecordGridDataSource() {
      return _orderBy(
        this.creditCardTotalRecords.map((record, index) => ({
          ...record,
          _rid: index + 1,
        })),
        "order"
      );
    },
    creditCardTotalRecordGridColumns() {
      return [
        {
          field: "_rid",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "id",
          visible: false,
        },
        {
          allowEditing: false,
          field: "card",
          headerText: "카드사",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          headerText: "거래금액/건수",
          columns: [
            {
              allowEditing: false,
              field: "approveAmount",
              headerText: "승인 금액",
              minWidth: 16,
              textAlign: "Right",
              type: "number",
              format: "N",
              width: 90,
            },
            {
              allowEditing: false,
              field: "approve",
              headerText: "승인 건수",
              minWidth: 16,
              textAlign: "Right",
              type: "number",
              format: "N",
              width: 90,
            },
            {
              allowEditing: false,
              field: "cancelAmount",
              headerText: "취소 금액",
              minWidth: 16,
              textAlign: "Right",
              type: "number",
              format: "N",
              width: 90,
            },
            {
              allowEditing: false,
              field: "cancel",
              headerText: "취소 건수",
              minWidth: 16,
              textAlign: "Right",
              type: "number",
              format: "N",
              width: 90,
            },
            {
              allowEditing: false,
              field: "salesAmount",
              headerText: "정상승인 금액",
              minWidth: 16,
              textAlign: "Right",
              type: "number",
              format: "N",
              width: 110,
            },
            {
              allowEditing: false,
              field: "normal",
              headerText: "정상승인 건수",
              minWidth: 16,
              textAlign: "Right",
              type: "number",
              format: "N",
              width: 110,
            },
          ]
        },
        {
          allowEditing: false,
          field: "commission",
          headerText: "수수료",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          format: "N",
          width: 90,
        },
        {
          allowEditing: false,
          field: "actualSalesAmount",
          headerText: "실매출금액",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          format: "N",
          width: 120,
        },
        {
          allowEditing: false,
          field: "",
          headerText: "비고",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          format: "N",
          width: 150,
        },
      ];
    },
    creditCardTotalRecordGridProps() {
      return {
        allowFiltering: true,
        allowGrouping: false,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: this.creditCardTotalRecordGridColumns,
        dataSource: this.creditCardTotalRecordGridDataSource,
        provides: [Aggregate, Resize, Filter],
        selectionSettings: { type: "Single", enableToggle: false },
      };
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },
    onCreditCardTotalRecordGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        },
        node
      } = args;

      if (field === "actualSalesAmount") {
        node.innerHTML = "<div e-mappinguid=\"grid-column1724\" class=\"e-headercelldiv\" style=\"text-align: right; line-height: normal;\"><span class=\"e-headertext\">실매출금액</span><br><span class=\"e-headertext\">(정상-수수료)</span></div><div class=\"e-sortfilterdiv e-icons\"></div><div class=\"e-filtermenudiv e-icons e-icon-filter\" e-mappinguid=\"e-flmenu-grid-column1724\"></div><div class=\"e-rhandler e-rcursor\" style=\"height: 24px;\"></div>";
      }
    },
    onCreditCardTotalRecordGridQueryCellInfo(args) {
      if (["cancel","cancelAmount"].includes(args.column.field)) {
        args.cell.style.color = "red";
      }
      if ([
        "approve",
        "cancel",
        "normal",
        "salesAmount",
        "approveAmount",
        "cancelAmount",
        "commission",
        "actualSalesAmount",
      ].includes(args.column.field) && args.data[args.column.field] === 0) {
        args.cell.innerText = "-";
      }
    },
    onCloseClick() {
      this.hide();
    },

    print() {
      const result = this.$refs.creditCardTotalRecordGrid.getGridBatchData();
      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions: this.popupSearchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },

    async init() {
      this.isLoading = true;

      try {
        const [now, workPlaces] = await Promise.all([
          GolfERPService.fetchNow(),
          GolfERPService.fetchWorkPlaces(),
        ]);

        this.now = now.toDate();
        this.workPlaces = workPlaces;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
